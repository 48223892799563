import React, { useEffect } from "react"
import LoadingScreen from "../components/LoadingScreen"
import QueryString from "query-string"
import useCommonQueries from "../hooks/useCommonQueries"
import { navigate } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { translatePath } from "../utils"

const PlayCoursePage = ({ location }) => {
  const { courseId } = QueryString.parse(location.search)
  const { getLastWatchVideoIdInCourse } = useCommonQueries()

  const { isAuthenticated, isLoading } = useAuth0()

  const getVideoToWatch = async () => {
    try {
      const videoId = await getLastWatchVideoIdInCourse({ courseId })
      navigate(`${translatePath("/watch")}/${videoId}`, { replace: true })
    } catch (error) {
      window.history.back()
    }
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!isAuthenticated) {
      return navigate(
        `${translatePath("/auth/sign-in")}?redirect=${location.href}`
      )
    } else {
      getVideoToWatch()
    }
  }, [isAuthenticated, isLoading])

  return <LoadingScreen />
}

export default PlayCoursePage
